exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-advertisers-data-platform-js": () => import("./../../../src/pages/advertisers-data-platform.js" /* webpackChunkName: "component---src-pages-advertisers-data-platform-js" */),
  "component---src-pages-advertisers-js": () => import("./../../../src/pages/advertisers.js" /* webpackChunkName: "component---src-pages-advertisers-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cognitive-pmps-js": () => import("./../../../src/pages/cognitive-pmps.js" /* webpackChunkName: "component---src-pages-cognitive-pmps-js" */),
  "component---src-pages-comscore-cognitive-pmps-list-js": () => import("./../../../src/pages/comscore-cognitive-pmps-list.js" /* webpackChunkName: "component---src-pages-comscore-cognitive-pmps-list-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-content-library-js": () => import("./../../../src/pages/content-library.js" /* webpackChunkName: "component---src-pages-content-library-js" */),
  "component---src-pages-contextual-pmps-js": () => import("./../../../src/pages/contextual-pmps.js" /* webpackChunkName: "component---src-pages-contextual-pmps-js" */),
  "component---src-pages-contextual-pmps-marketplace-js": () => import("./../../../src/pages/contextual-pmps-marketplace.js" /* webpackChunkName: "component---src-pages-contextual-pmps-marketplace-js" */),
  "component---src-pages-data-agency-js": () => import("./../../../src/pages/data-agency.js" /* webpackChunkName: "component---src-pages-data-agency-js" */),
  "component---src-pages-data-marketplace-js": () => import("./../../../src/pages/data-marketplace.js" /* webpackChunkName: "component---src-pages-data-marketplace-js" */),
  "component---src-pages-data-platform-js": () => import("./../../../src/pages/data-platform.js" /* webpackChunkName: "component---src-pages-data-platform-js" */),
  "component---src-pages-dmp-segments-list-js": () => import("./../../../src/pages/dmp-segments-list.js" /* webpackChunkName: "component---src-pages-dmp-segments-list-js" */),
  "component---src-pages-documents-audigent-amazon-cx-m-msa-sow-js": () => import("./../../../src/pages/documents/audigent_amazon_CxM_MSA_SOW.js" /* webpackChunkName: "component---src-pages-documents-audigent-amazon-cx-m-msa-sow-js" */),
  "component---src-pages-documents-audigent-ccpa-authorized-agent-form-js": () => import("./../../../src/pages/documents/audigent_CCPA_authorized_agent_form.js" /* webpackChunkName: "component---src-pages-documents-audigent-ccpa-authorized-agent-form-js" */),
  "component---src-pages-documents-audigent-msa-js": () => import("./../../../src/pages/documents/audigent_msa.js" /* webpackChunkName: "component---src-pages-documents-audigent-msa-js" */),
  "component---src-pages-documents-audigent-publisher-dpa-js": () => import("./../../../src/pages/documents/audigent_publisher_DPA.js" /* webpackChunkName: "component---src-pages-documents-audigent-publisher-dpa-js" */),
  "component---src-pages-documents-audigent-subprocessors-js": () => import("./../../../src/pages/documents/audigent_subprocessors.js" /* webpackChunkName: "component---src-pages-documents-audigent-subprocessors-js" */),
  "component---src-pages-first-unit-tsx": () => import("./../../../src/pages/first-unit.tsx" /* webpackChunkName: "component---src-pages-first-unit-tsx" */),
  "component---src-pages-gold-standard-js": () => import("./../../../src/pages/gold-standard.js" /* webpackChunkName: "component---src-pages-gold-standard-js" */),
  "component---src-pages-hadron-id-js": () => import("./../../../src/pages/hadron-id.js" /* webpackChunkName: "component---src-pages-hadron-id-js" */),
  "component---src-pages-halo-js": () => import("./../../../src/pages/halo.js" /* webpackChunkName: "component---src-pages-halo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managedservices-js": () => import("./../../../src/pages/managedservices.js" /* webpackChunkName: "component---src-pages-managedservices-js" */),
  "component---src-pages-platform-privacy-policy-js": () => import("./../../../src/pages/platform-privacy-policy.js" /* webpackChunkName: "component---src-pages-platform-privacy-policy-js" */),
  "component---src-pages-privacy-center-js": () => import("./../../../src/pages/privacy-center.js" /* webpackChunkName: "component---src-pages-privacy-center-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-publisher-id-sow-js": () => import("./../../../src/pages/publisher-id-sow.js" /* webpackChunkName: "component---src-pages-publisher-id-sow-js" */),
  "component---src-pages-publisher-iddata-sow-js": () => import("./../../../src/pages/publisher-iddata-sow.js" /* webpackChunkName: "component---src-pages-publisher-iddata-sow-js" */),
  "component---src-pages-publishers-js": () => import("./../../../src/pages/publishers.js" /* webpackChunkName: "component---src-pages-publishers-js" */),
  "component---src-pages-red-ventures-service-form-conformation-index-tsx": () => import("./../../../src/pages/red-ventures-service-form-conformation/index.tsx" /* webpackChunkName: "component---src-pages-red-ventures-service-form-conformation-index-tsx" */),
  "component---src-pages-red-ventures-service-form-index-tsx": () => import("./../../../src/pages/red-ventures-service-form/index.tsx" /* webpackChunkName: "component---src-pages-red-ventures-service-form-index-tsx" */),
  "component---src-pages-red-ventures-service-form-service-form-conformation-tsx": () => import("./../../../src/pages/red-ventures-service-form/ServiceFormConformation.tsx" /* webpackChunkName: "component---src-pages-red-ventures-service-form-service-form-conformation-tsx" */),
  "component---src-pages-red-ventures-service-form-service-form-tsx": () => import("./../../../src/pages/red-ventures-service-form/ServiceForm.tsx" /* webpackChunkName: "component---src-pages-red-ventures-service-form-service-form-tsx" */),
  "component---src-pages-smart-otp-js": () => import("./../../../src/pages/smart-otp.js" /* webpackChunkName: "component---src-pages-smart-otp-js" */),
  "component---src-pages-smart-pmps-js": () => import("./../../../src/pages/smart-pmps.js" /* webpackChunkName: "component---src-pages-smart-pmps-js" */),
  "component---src-pages-smart-pmps-list-js": () => import("./../../../src/pages/smart-pmps-list.js" /* webpackChunkName: "component---src-pages-smart-pmps-list-js" */),
  "component---src-pages-transunion-pmps-search-js": () => import("./../../../src/pages/transunion-pmps-search.js" /* webpackChunkName: "component---src-pages-transunion-pmps-search-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog_post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case_study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-content-library-entry-js": () => import("./../../../src/templates/content_library_entry.js" /* webpackChunkName: "component---src-templates-content-library-entry-js" */)
}

